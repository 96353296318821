import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import Contact from './Components/Pages/Contact';
import Services from './Components/Pages/Services';
import WasteAlternatives from "./Components/Pages/Waste-Alternatives";
import RecicleProgram from './Components/Pages/Recicle-Program';
import DisasterRollOff from './Components/Pages/Disaster-Roll-Off';

const routes = [
  { path: '/', component: <Home />, exact: true },
  { path: '/about', component: <About /> },
  { path: '/contact', component: <Contact /> },
  { path: '/services', component: <Services /> },
  { path: '/waste-alternatives', component: <WasteAlternatives /> },
  { path: '/recicle-program', component: <RecicleProgram /> },
  { path: '/disaster-roll-off', component: <DisasterRollOff /> },
];

export default routes;