import React from "react";
import Container from 'react-bootstrap/Container';
import "./../../App.css";
import "./banner-form.css"



export default function BannerForm() {
    

    return(
        <>
        <Container>
            <div className="row justify-content-end">
                <div className="col-10 rounded-blur py-4 px-5">
                    <h2 className="wa-h2-tittle">Get a Quote</h2>
                    <form id="contact-form" method="POST">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <input type="text" className="form-control no-color" placeholder="Name"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input type="tel" className="form-control no-color" aria-describedby="emailHelp" placeholder="Phone"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input type="email" className="form-control no-color" aria-describedby="emailHelp" placeholder="Email"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <textarea className="form-control no-color" rows="1" placeholder="Message"/>
                                </div>
                            </div>
                            
                            <div className="col-2 d-grid gap-2">
                                <button type="submit" className="btn btn-primary wa-button green wa-no-border">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Container>
        </>
    )
    
}