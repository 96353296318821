// Filename - pages/about.js

import React from "react";
import {motion} from "framer-motion"
import trans from "../../transition";

const About = () => {
	return (
		<motion.div
		initial={{translateX: 500}}
		animate={{translateX:0}}
		exit={{translateX:-500}}
		transition={{duration:0.7, ease:[0.22,1,0.36,1]}} 
	>
		<div>
			<h1>
				GeeksforGeeks is a Computer Science portal
				for geeks.
			</h1>
			<img src={require('./../../images/logo_navbar.png')}></img>
		</div>
		</motion.div>
	);
};

export default trans(About);