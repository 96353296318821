import React from "react";
import trans from "../../transition";
import {motion} from "framer-motion"

const Contact = () => {
	return (
		<motion.div
		initial={{translateX: 500}}
		animate={{translateX:0}}
		exit={{translateX:-500}}
		transition={{duration:0.7, ease:[0.22,1,0.36,1]}} 
	>
		<div>
			<h1>
				Mail us on 
				feedback@geeksforgeeks.org
			</h1>
		</div>
		</motion.div>
	);
};

export default trans(Contact);