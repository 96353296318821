
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import routes from './routes';

import Navbar_Menu from './Components/Internals/Navbar';

import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';


function App() {

  const location = useLocation()

  return (
    <>
    <Navbar_Menu />
    <AnimatePresence mode='wait' initial={false}>
      <Routes location={location} key={location.pathname}>
          {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
      </Routes>    
    </AnimatePresence>
    </>
  );
}

export default App;
