import React from "react";
import transit from "../../transition";

const DisasterRollOff = () => {
	return (
		<div>
			<h1>
				THIS IS THE Disaster Roll Off page
			</h1>
		</div>
	);
};

export default transit(DisasterRollOff);