
import React, { useState } from "react";
import "./../../App.css";

import { NavLink, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./Navbar.css"

function Navbar_Menu() {

  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const handleClose = () => setMenuOpen(false)

  return (
  <>
    <Navbar sticky="top" fixed="top" key="false" expand="false" className="bg-transparent py-0">
    <Container>
      <Navbar.Brand as={Link} to={"/"} className="py-3 px-3 mx-0 bg-white">
        <img src={require('./../../images/logo_navbar.png')}></img>
      </Navbar.Brand>
      <Nav className="round-left-bottom main-menu-top">
        <Nav.Link className="py-3 px-3 wa-primary">
          <Navbar.Toggle  aria-controls={`offcanvasNavbar-expand-false`} onClick={toggleMenu} className="py-0"/>
        </Nav.Link > 
        <Nav.Link href="tel:+16015552122" className="py-3 px-4 wa-secondary round-left-bottom">
          <img src={require('./../../images/phone_icon.png')}></img>
        </Nav.Link>
      </Nav>
      <Navbar.Offcanvas
        id={`offcanvasNavbar-expand-false`}
        aria-labelledby={`offcanvasNavbarLabel-expand-false`}
        placement="end"
        restoreFocus={false}
        show={menuOpen}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link as={Link} to={"/about"} onClick={toggleMenu}>About</Nav.Link>
            <Nav.Link as={NavLink} to={"/services"} onClick={toggleMenu}>Services</Nav.Link>
            <Nav.Link as={NavLink} to={"/waste-alternatives"} onClick={toggleMenu}>Waste Alternatives</Nav.Link>
            <Nav.Link as={NavLink} to={"/recicle-program"} onClick={toggleMenu}>Recicle Program</Nav.Link>
            <Nav.Link as={NavLink} to={"/disaster-roll-off"} onClick={toggleMenu}>Disaster Roll-Off</Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>
  </>
  );
}

export default Navbar_Menu;