// Filename - pages/contact.js
import transit from "../../transition";
import {motion} from "framer-motion"

import React from "react";

const Services = () => {
	return (
		<>
		<motion.div
            initial={{translateX: 500}}
            animate={{translateX:0}}
            exit={{translateX:-500}}
            transition={{duration:0.7, ease:[0.22,1,0.36,1]}} 
        >
		<div>
			<h1>
				THIS IS THE SERVICE PAGE
			</h1>
		</div>


		</motion.div>
		</>
	);
};

export default transit(Services);
