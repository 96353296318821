import React from "react";
import transit from "../../transition";
import {motion} from "framer-motion"

const RecicleProgram = () => {
	return (
		<>
		        <motion.div
            initial={{translateX: 500}}
            animate={{translateX:0}}
            exit={{translateX:-500}}
            transition={{duration:0.7, ease:[0.22,1,0.36,1]}} 
        >
			<div>
				<h1>
					THIS IS THE Recicle Program page
				</h1>
			</div>


		</motion.div>
		</>
	);
};

export default transit(RecicleProgram);