import React, { useEffect, useState } from "react";
import "./reviews.css"
import Container from 'react-bootstrap/Container';
import "./../../App.css";


export default function Reviews() {

	const images = [
		require('./../../images/reviewComponenImg/review_photo1.webp'),
		require('./../../images/reviewComponenImg/review_photo2.png'),
		require('./../../images/reviewComponenImg/review_photo1.webp')
	  ];

	const titleText = [
		"Nombre 1",
		"Nombre 2",
		"Nombre 3",
	];

	const reviewText = [
		"Nombre 1 - Waste Alternatives is a trustworthy, honest company. You can believe what they tell you, and the follow through is exceptional.",
		"Nombre 2 - Waste Alternatives is a trustworthy, honest company. You can believe what they tell you, and the follow through is exceptional.",
		"Nombre 3 - Waste Alternatives is a trustworthy, honest company. You can believe what they tell you, and the follow through is exceptional.",
	];
	
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const slideLeft = () => {

		setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);

    }

	const slideRight = () => {

		setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }

	useEffect(() => {
		const imageElement = document.getElementById('img-to-animate');
		const tittleElement = document.getElementById('text-to-animate');
		if (imageElement && tittleElement) {
		  imageElement.classList.add('to-animate');
		  tittleElement.classList.add('to-animate');
		  setTimeout(() => {
			imageElement.classList.remove('to-animate');
			tittleElement.classList.remove('to-animate');
		  }, 500);
		}
	  }, [currentImageIndex]);

	return (
		<>
		<div className="review-bg">
			<Container>
				<div className="row">
					<div className="col-2">
						<div style={{height: "450px"}}>
							<div>
							<h2 className="wa-tittle blue testimonial-tittle">TESTIMONIALS</h2>
							<img src={require('./../../images/reviewComponenImg/leaf.png')} className="float-end leaf"></img>
							</div>
						</div>
					</div>
					<div className="col-4 py-5">
						<img src={images[currentImageIndex]} width={408} id="img-to-animate" className="to-animate"></img>
					</div>
					<div className="col-4 p-top-50 z-10">
						<h3 className="py-0 ps-3" id="text-to-animate">{titleText[currentImageIndex]}</h3>
						<div className="py-4 ps-4 pe-3 my-4 b-review">
							<p>{reviewText[currentImageIndex]}</p>
							<img src={require('./../../images/reviewComponenImg/Stars.png')}></img>
						</div>
						<button type="button" className="wa-button green">View All Reviews</button>
					</div>
					<div className="col-2 p-top-80" onClick={slideRight}>
					</div>
					<div className="col-12 blue-overlay">
						<div className="py-4 ps-5 z-10" onClick={slideLeft}>
							<img src={require('./../../images/reviewComponenImg/left_arrow.png')} className="mx-auto d-block" id=""></img>
						</div>
						<div className="py-4 pe-5 z-10" onClick={slideRight}>
							<img src={require('./../../images/reviewComponenImg/right_arrow.png')} className="mx-auto d-block"></img>
						</div>
					</div>
				</div>
			</Container>
		</div>
			
		</>
	);

}