 
import trans from "../../transition";
import React, {useEffect} from "react";
import Reviews from "../Internals/Reviews";
import { Container} from "react-bootstrap";
import "./pages.css"
import $ from "jquery"
import BannerForm from "../Internals/BannerForm";
import Lightbox from 'bs5-lightbox'
import {motion} from "framer-motion"
window.jQuery = $;
 
const Home = () => {

    useEffect(() =>{
       $.getScript('https://cdnjs.cloudflare.com/ajax/libs/lettering.js/0.6.1/jquery.lettering.min.js', function(data, textStatus){
            $(".smokemonster").lettering();
        })
        document.querySelectorAll('.my-lightbox-toggle').forEach(el => el.addEventListener('click', Lightbox.initialize));
    },[])
    const textStyles = {
        WebkitTextStrokeWidth: '2px',
    };

    useEffect(() => {
    
        const observerCallback = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animated1-about');
            }
          });
        };
    
        const observer = new IntersectionObserver(observerCallback, {
          threshold: [0.7],
          trackVisibility: true,
          delay: 100,
        });
    
        const observerCallback1 = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animated-about');
            }
          });
        };
    
        const observer1 = new IntersectionObserver(observerCallback1, {
          threshold: [0.7],
          trackVisibility: true,
          delay: 100,
        });
    
        const toAnimateElement = document.querySelector('.toanimate');
        const toAnimateElement1 = document.querySelector('.toanimate1');
    
        if (toAnimateElement) {
          observer1.observe(toAnimateElement);
        }
    
        if (toAnimateElement1) {
          observer.observe(toAnimateElement1);
        }
      }, []); // Empty dependency array to ensure the effect runs only once on mount
    
    
    return (
        <>
        <motion.div
            initial={{translateX: 500}}
            animate={{translateX:0}}
            exit={{translateX:-500}}
            transition={{duration:0.7, ease:[0.22,1,0.36,1]}} 
        >
        <div className="banner-home">
            <Container>
                <h1 className="header-h1-banner">Waste And Recycling Management In America</h1>
                {/*<h2 style={textStyles} className="header-h2-banner pt-4">Cost-Effective</h2> */}
                <h2 className="header-h2-banner pt-4">Cost-Effective</h2>
                <h2 className="smokemonster">WASTE SOLUTIONS</h2>
                <p className="banner-p">Partner with Waste Alternatives, your eco-conscious partner in waste and
                recycling management. We dispose of waste in an eco-<br />friendly manner, <b>at a cost 
                lower than the industry average.</b> We offer a variety of services, from Waste 
                Management to Roll Off <br />Services. Call today to learn more about why Waste Alternatives 
                is your best option. </p>
                <button type="button" className="wa-button blue">Contact Us</button>
                <BannerForm /> 
            </Container>
        </div>
            <Container className="neg-mar-80 px-4 mb-5">
                <div className="row align-items-end">
                    <div className="col wms service-box p-0">
                        <div className="py-4 px-4 align-self-end animated">
                            <div className="row p-0 align-items-end bottom-end">
                                <div className="col-12">
                                <h2 className="h2-white">Waste Management Services</h2>
                                </div>
                                <div className="col-12 text-to-show">
                                    <p className="text-white">Aqui el texto</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button className="wa-button green">Aqui va algo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 recycle-ser service-box p-0">
                        <div className="py-4 px-4 align-self-end animated">
                            <div className="row p-0 align-items-end bottom-end">
                                <div className="col-12">
                                <h2 className="h2-white">Waste Management Services</h2>
                                </div>
                                <div className="col-12 text-to-show">
                                    <p className="text-white">Aqui el texto</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button className="wa-button green">Aqui va algo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col dro service-box p-0">
                        <div className="py-4 px-4 align-self-end animated">
                            <div className="row p-0 align-items-end bottom-end">
                                <div className="col-12">
                                <h2 className="h2-white">Waste Management Services</h2>
                                </div>
                                <div className="col-12 text-to-show">
                                    <p className="text-white">Aqui el texto</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button className="wa-button green">Aqui va algo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="wa-button green mt-5">Aqui va algo</button>
            </Container>
        <div className="why-back-logo">
            <Container className="mt-5">
                <div className="why-us-back p-0">
                    <div className="row ">
                        <div className="col-5 offset-2 p-0 darker-blue">
                            <div className="inner">
                                <h2 className="wa-tittle white why-text"><span className="green">WHY CHOOSE</span> OUR SERVICES</h2>
                                <img src={require('./../../images/HomeImg/question-ser.webp')} className="question-img" width={33}></img>
                            </div>
                        </div>
                    </div>
                    <div className="row pl-1" >
                        <div className="col-6 p-0 m-0">
                            <img src={require('./../../images/HomeImg/why-us-2.webp')} className="why-img" width={588}></img>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-4">
                        <div className="row py-2">
                            <div className="col-2 pe-0">
                                <p className="why-number mb-0">1<img src={require('./../../images/reviewComponenImg/leaf.png')} className="why-number-img" width={8}></img></p>
                                
                            </div>
                            <div className="col-10 ps-1">
                                <p className="why-text-small mb-0">We offer <span className="green">15% off</span> your current invoice.</p>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-2 pe-0">
                                <p className="why-number mb-0">2<img src={require('./../../images/reviewComponenImg/leaf.png')} className="why-number-img" width={8}></img></p>
                                
                            </div>
                            <div className="col-10 ps-1">
                                <p className="why-text-small mb-0">We <span className="green">buyout your current</span> waste contract.</p>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-2 pe-0">
                                <p className="why-number mb-0">3<img src={require('./../../images/reviewComponenImg/leaf.png')} className="why-number-img" width={8}></img></p>
                                
                            </div>
                            <div className="col-10 ps-1">
                                <p className="why-text-small mb-0">We <span className="green">offer long term</span>  locked rates.</p>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-2 pe-0">
                                <p className="why-number mb-0">4<img src={require('./../../images/reviewComponenImg/leaf.png')} className="why-number-img" width={8}></img></p>
                                
                            </div>
                            <div className="col-10 ps-1">
                                <p className="why-text-small mb-0">We do this all at <span className="green">no penalty or cost to you.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row mt-4">
                            <div className="col mt-1">
                                <p className="wa-text">
                                Choosing Waste Alternatives means opting for excellence in waste management. We ensure your waste is picked up on time and disposed of correctly, offering convenience alongside our commitment to the environment. 
                                <br /><br />
                                More than just a waste management company, we are a team dedicated to providing efficient, tailor-made solutions for each client. No hassle, no hidden fees, no back and forth. 
                                <br /><br />
                                Our extensive experience and customer-centric approach make us the ideal choice for those seeking a reliable partner–because waste management should be simple. 
                                </p>
                                <button className="wa-button green">Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
        <div className="interested">
            <Container>
                    <div className="row align-items-center">
                        <div className="col-5 neg-mar-60">
                            <button className="wa-button white">Start Saving</button>
                            <img src={require('./../../images/HomeImg/interested-1.webp')} className="inter-img-1" width={310}></img>
                        </div>
                        <div className="col-7">
                            <div className="inter-img-2">
                                <img src={require('./../../images/HomeImg/interested-2.webp')} className="" width={384}></img>
                            </div>
                            <div className="image-to-right">
                                <img src={require('./../../images/HomeImg/interested-3.webp')} className="" width={306}></img>
                            </div>
                        </div>
                    </div>
            </Container>
        </div>
        <Container>
            <div className="row" >
                <div className="col-5">
                    <img src={require('./../../images/HomeImg/bottle.webp')} className="pt-80 scrolling-image" width={270} ></img>
                </div>
                <div className="col-7">
                    <h2 className="wa-tittle blue ps-50"><span className="green">INTERESTED</span><br /> IN SAVING MONEY ON YOUR BILL</h2>
                    <img src={require('./../../images/HomeImg/question-green.webp')} className="question-green" width={33}></img>
                    <p className="wa-text ps-50 pe-125">
                    Transform your waste management with Waste Alternatives. Efficiency and sustainability at your service. Contact us now and start making a real difference in the environment and on your bill!                        <br /><br />
                    </p>
                </div>
            </div>
        </Container>
        <Container className="py-5 ">
            <div className="row w-1200 mx-auto py-5">
                <div className="col-12">
                    <h2 className="wa-tittle blue"><span className="green">ABOUT</span> WASTE ALTERNATIVES</h2>
                    <img src={require('./../../images/reviewComponenImg/leaf.png')} className="leaf-about" width={18}></img>
                </div>
                <div className="col-6 ps-0">
                    <p className="wa-text home-about-left"> At Waste Alternatives, we are passionately committed to transforming waste management by blending innovative
                     and efficient solutions with an unwavering commitment to environmental protection. Our team, comprised of expert and friendly professionals,
                      comes from leading brands in the industry and focuses on providing personalized, approachable service experiences.
                    </p>
                    <div className="toanimate ">
                        <img src={require('./../../images/HomeImg/home-about-1.webp')} width={588}></img>
                    </div>
                </div>
                <div className="col-6 pe-0">
                    <div className="toanimate1 pt-3">
                        <img src={require('./../../images/HomeImg/home-about-2.webp')} className="" ></img>
                    </div>
                    <p className="wa-text home-about-right"> We tirelessly strive to minimize our clients' environmental footprint, offering accessible, sustainable
                     waste management services tailored to their specific needs.<b> At Waste Alternatives, each client is a valued collaborator in our mission to
                      build a cleaner, greener, and more responsible future for everyone.</b>
                    </p>
                </div>

            </div>
        </Container>
        
        <Container className="pt-5">
            <div className="row w-1200 mx-auto py-5">
                <div className="col-12 p-0 m-0">
                    <h2 className="wa-tittle blue"><span className="green">OUR </span>PROJECTS</h2>
                    <img src={require('./../../images/reviewComponenImg/leaf.png')} className="leaf-projects" width={18}></img>
                </div>
                <div className="col-3 p-0 m-0">
                    <a href={require('./../../images/HomeImg/project-1.webp')} data-toggle="lightbox" data-gallery="projects-gallery" className="my-lightbox-toggle">
                        <img src={require('./../../images/HomeImg/project-1.webp')} className="img-fluid" ></img>
                    </a>
                    <a href={require('./../../images/HomeImg/projects-2.webp')} data-toggle="lightbox" data-gallery="projects-gallery" className="my-lightbox-toggle">
                        <img src={require('./../../images/HomeImg/projects-2.webp')} className="img-fluid" ></img>
                    </a>
                    <img src={require('./../../images/HomeImg/bottle.webp')} className="" width={300}></img>
                </div>
                <div className="col-3 p-0 m-0 pt-5">
                    <a href={require('./../../images/HomeImg/proyects-3.webp')} data-toggle="lightbox" data-gallery="projects-gallery" className="my-lightbox-toggle">
                        <img src={require('./../../images/HomeImg/proyects-3.webp')} className="pt-3 img-fluid"></img>
                    </a>
                    <a href={require('./../../images/HomeImg/proyects-4.webp')} data-toggle="lightbox" data-gallery="projects-gallery" className="my-lightbox-toggle">
                        <img src={require('./../../images/HomeImg/proyects-4.webp')} className="img-fluid"></img>
                    </a>
                </div>
                <div className="col-6 p-0 m-0 pt-5">
                    <a href={require('./../../images/HomeImg/projects-5.webp')} data-toggle="lightbox" data-gallery="projects-gallery" className="my-lightbox-toggle">
                        <img src={require('./../../images/HomeImg/projects-5.webp')} className="pt-5 img-fluid"></img>
                    </a>
                </div>
            </div>
        </Container>
        <Reviews />
        </motion.div>
        </>
    );
};
 
export default trans(Home);